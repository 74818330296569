<template>
  <div class="details">
    <!-- 返回键 -->
    <div class="btn-back" @click="back()">
      <i class="el-icon-arrow-left"></i>
      <span>{{$t('Back')}}</span>
    </div>
    <!-----------------------------------------------商会标题区域----------------------->
    <div class="detail-view">
      <div class="item-box">
        <!--产品类型-->
        <div class="top-product-type-box" v-if="detailObj.productTypes && detailObj.productTypes.length>0 && detailObj.postType !== 3">
          <span># {{threeProduct(detailObj.productTypes)}}</span>   <!--仅显示前3条，多余的用省略号显示-->
        </div>
    
        <!-- 顶部区域 -->
        <div class="top-box">
          <!--logo-->
          <div class="top-left-box">
            <div class="img-box">
              <img :src="detailObj.chamberAvatar" alt="商会头像"/>
            </div>
            <p>{{ detailObj.chamberName +this.$t('Member')}}</p>
          </div>
      
          <!--标题-->
          <div class="top-right-box">
            <div class="info-box">
              <div class="title-box">
                <!--如果有产品名称就显示（针对买家卖家）-->
                <b v-if="detailObj.productName">【{{detailObj.productName}}】</b>
                {{detailObj.productTitle||detailObj.partnerTitle}}
              </div>
              <div v-if="postType===2">
                <ul class="info-detail" style="list-style: none">
                  <li class="ellipsis">{{$t('ProjectIntro')}}：{{ detailObj.partnerDescription }}</li>
                  <li class="ellipsis" v-if="detailObj.cooperateWay">{{$t('CooperationMethod')}}：{{ detailObj.cooperateWay }}</li>
                  <li class="ellipsis" v-if="detailObj.partnerRequest">{{$t('CoopRequest')}}：{{ detailObj.partnerRequest }}</li>
                </ul>
              </div>
              <div class="accept-price-box" v-else>
                <span v-if="postType === 0 && detailObj.acceptPrice!==0">{{ $t('TendPrice') }}: {{detailObj.priceType +'&nbsp'+detailObj.acceptPrice}} <span v-if="detailObj.unit">{{ '/' + detailObj.unit }}</span> </span>
                <span v-if="postType === 1">{{ $t('SellPrice') }}: {{detailObj.priceType +'&nbsp'+detailObj.acceptPrice+'/'+detailObj.unit+'&nbsp'+isNegotiable(detailObj.negotiable)}}</span>
              </div>
            </div>
          </div>
        </div>
    
        <!-- 底部区域 -->
        <ul class="bottom-box">
          <li class="flex2">id: {{detailObj.postId||detailObj.partnerId}}</li>
          <li class="flex2">{{$t('ValidTill')}}: {{detailObj.validDate}}</li>
          <li class="flex1">{{detailObj.postCountryId ||detailObj.postingCountryId}}</li>
          <li class="flex2">{{$t('PublishTime')}}: {{detailObj.createTime||detailObj.createDate}}</li>
        </ul>
      </div>
    </div>
    
  <!---------------------------------------------  详情区域---------------------------->
    <div class="detail-detail" v-if="isBuyerHasDetail">
      <!--图片部分-->
      <div class="detail-img" v-if="detailObj.pictureAppendixList && detailObj.pictureAppendixList.length>0">
        <div>
          <img class="imgs" :src="detailObj.pictureAppendixList[0]" alt="图片">
          <div class="pile" @click="openCarousel"><i class="el-icon-coin"></i></div>
        </div>
        <span v-if="detailObj.productName">{{detailObj.productName}}</span>
      </div>
    <!--  文字部分-->
      <div class="detail-text">
        <!--  合作伙伴-->
        <div class="detail-info" v-if="postType===2">
          <!--  项目介绍-->
          <div>
            <h4>{{$t('ProjectIntro')}}:</h4>
            <p>{{detailObj.partnerDescription}}</p>
            <!--<p>{{pp}}</p>-->
          </div>
          <!--  合作方式-->
          <div v-if="detailObj.cooperateWay">
            <h4>{{$t('CooperationMethod')}}:</h4>
            <p>{{detailObj.cooperateWay}}</p>
          </div>
          <!--  我的要求-->
          <div v-if="detailObj.partnerRequest">
            <h4>{{$t('MyRequest')}}:</h4>
            <p>{{detailObj.partnerRequest}}</p>
          </div>
          <!--  附件-->
          <!--<div class="attachments" v-if="detailObj.fileAppendixList.length">-->
          <div class="attachments" v-if="detailObj.fileAppendixList">
            <ul>
              <li v-for="(file,index) in detailObj.fileAppendixList" :key="index">
                <i class="el-icon-paperclip"></i>
                <a :href="file">{{file}}</a>
              </li>
            </ul>
          </div>
        </div>
        <!--买家卖家-->
        <div class="detail-info" v-else>
          <!--  产品类型-->
          <div v-if="detailObj.productTypes && detailObj.productTypes.length">
            <h4>{{$t('ProductType')}}:</h4>
            <!--<p>{{detailObj.productTypes}}</p>-->
            <p>{{toString(detailObj.productTypes)}}</p>
          </div>
          <!--  产品要求/产品详情-->
          <div v-if="detailObj.productRequest">
            <h4 v-if="postType === 0">{{$t('ProductRequest')}}:</h4>
            <h4 v-else>{{$t('ProductDetail')}}:</h4>
            <p>{{detailObj.productRequest}}</p>
          </div>
          <!--  附件-->
          <!--<div class="attachments" v-if="detailObj.fileAppendixList.length">-->
          <div class="attachments" v-if="detailObj.fileAppendixList && detailObj.fileAppendixList.length">
            <ul>
              <li v-for="(file,index) in detailObj.fileAppendixList" :key="index">
                <i class="el-icon-paperclip"></i>
                <a :href="file">{{file}}</a>
              </li>
            </ul>
          </div>
          
        </div>
      </div>
    </div>
  <!----------------------------------------  补充区域------------------------------>
    <div class="detail-additional" v-if="isUser===2">
      <h4 v-if="isUser===2">{{$t('ReportReason')}}：</h4>
      <h4 v-else>{{$t('AdditionInfo')}}：</h4>
<!--      <el-input
        type="textarea"
        disabled
        :placeholder="$t('DefaultRefuse')"
        v-model="detailObj.reportReason">
      </el-input>-->
      <div class="detail-style">{{detailObj.reportReason||$t('DefaultRefuse')}}</div>
    </div>
  <!---------------------------------------  操作按钮------------------------------->
    <div class="detail-operation">
    <!--  左边按钮-->
    <!--  通过  (成员/接收)-->
      <el-button class="btn-to-p" v-if="postTypeStatus===2 || postTypeStatus===6" @click="reviewPost(detailObj.postId||detailObj.partnerId,0)">{{$t('Pass')}}</el-button>
    <!--  人工通过  (成员/接收)-->
      <el-button class="btn-man-p" v-if="postTypeStatus===1 ||postTypeStatus===5" disabled>{{$t('ManPass')}}</el-button>
    <!--  自动通过  (成员/接收)-->
      <el-button class="btn-auto-p" v-if="postTypeStatus===0 ||postTypeStatus===4" disabled>{{$t('AutoPass')}}</el-button>
    <!--  全系统删除该贴  (举报)-->
      <el-button class="btn-auto-p" v-if="postTypeStatus===11" @click="deletePosts(reportId,2)">{{$t('AllDelete')}}</el-button>
      
    <!--  右边按钮-->
    <!--  拒绝  (成员/接收)-->
      <el-button class="btn-no" v-if="postTypeStatus===2 || postTypeStatus===6" @click="reviewPost(detailObj.postId||detailObj.partnerId,1)">{{$t('Refuse')}}</el-button>
    <!--  举报无效  (举报)-->
      <el-button class="btn-no" v-if="postTypeStatus===11" @click="reviewReportPost(reportId,0)">{{$t('DoInvalidReport')}}</el-button>
    <!--  从所有商会撤回该贴（成员帖子）-->
      <el-button class="btn-no" v-if="postTypeStatus===0 || postTypeStatus===1" @click="deletePosts(detailObj.postId||detailObj.partnerId,2)">{{$t('RevokeAll')}}</el-button>
    <!--  从本商会撤回该贴（接收帖子）-->
      <el-button class="btn-no" v-if="postTypeStatus===4 || postTypeStatus===5" @click="deletePosts(detailObj.postId||detailObj.partnerId,1)">{{$t('RevokeThis')}}</el-button>
      
    <!--  单状态-->
    <!--  人工拒绝  (成员/接收)-->
      <el-button class="btn-no" v-if="postTypeStatus===3 || postTypeStatus===7" disabled>{{$t('ManRefused')}}</el-button>
    <!--  已过期  (成员/接收)-->
      <el-button class="btn-no" v-if="postTypeStatus===12 || postTypeStatus===13" disabled>{{$t('AutoRefused')}}</el-button>
    <!--  已删除  (举报)-->
      <el-button class="btn-no" v-if="postTypeStatus===8" disabled>{{$t('Deleted')}}</el-button>
    <!--  该举报已无效  (举报)-->
      <el-button class="btn-no" v-if="postTypeStatus===9" disabled>{{$t('InvalidReport')}}</el-button>
    <!--  用户撤销举报  (举报)-->
      <el-button class="btn-no" v-if="postTypeStatus===10" disabled>{{$t('Withdraw')}}</el-button>

    </div>
  <!------------------------------------  上一条/下一条------------------------------->
    <div class="pre-aft">
      <el-button @click="preAfter(0)">{{$t('Pre')}}</el-button>
      <el-button @click="preAfter(1)">{{$t('After')}}</el-button>
    </div>
    <Carousel
      v-if="carouselFlag"
      :carouselFlag="carouselFlag"
      :pictureList="detailObj.pictureAppendixList"
      @closeDialog="carouselFlag = false"
    ></Carousel>
  </div>
</template>
  
  <script>
import api from "@/fetch/api";
import mixin from "@/assets/js/mixin";
import Carousel from "@/common/Carousel.vue";
export default {
  mixins: [mixin],
  name: "DetailedInformation",
  components:{Carousel},
  data() {
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData
    return {
      adminId:data.id,
      postId:this.$route.query.id,
      reportId:this.$route.query.reportId,
      postType: Number(this.$route.query.postType),
      right: Number(data.postPermission),
      initDetailParam:{
        currentAdmin:data.id,
      },
      detailObj:{},
      status:4,   // 0 自动通过  1 人工通过  2 待审核  3 被拒绝  4 过期自动拒绝
      isUser:Number(this.$route.query.isUser),  // 0 从接收帖子来   1 从成员帖子传过来   2 从举报帖子来
      textarea:'',
      carouselFlag:false,
      chamberId:data.chamberId,
    };
  },
  created() {
    this.initDetailData(this.postType,this.postId,this.reportId);
    // console.log('this.postType ->',this.postType)
  },
  computed:{
    // return    0：成员帖子自动通过   1: 成员帖子人工通过  2：成员帖子待审核  3：成员帖子已拒绝   12：成员帖子自动拒绝
    // return    4：接收帖子自动通过   5: 接收帖子人工通过  6：接收帖子待审核  7：接收帖子已拒绝   13：接收帖子自动拒绝
    // return    8：举报帖子已删除     9: 举报帖子已无效   10：举报帖子被用户撤销  11：举报帖子待审核
    postTypeStatus(){
      // console.log('this.isUser, this.status ->',this.isUser, this.status)
      switch (this.isUser) {
        // 接收帖子
        case 0:
          if(this.status === 0) return 4
          else if(this.status === 1) return 5
          else if(this.status === 2) return 6
          else if(this.status === 3) return 7
          else if(this.status === 4) return 12
          break
        // 成员帖子
        case 1:
          if(this.status === 0) return 0
          else if(this.status === 1) return 1
          else if(this.status === 2) return 2
          else if(this.status === 3) return 3
          else if(this.status === 4) return 13
          break
        // 举报后端状态： 0：待审核  1：举报成功  2：举报失败
        case 2:
          if(this.status === 0) return 11
          else if(this.status === 1) return 8
          else if(this.status === 2) return 9
          // else if(this.status === 3) return 10
          break
      }
    },
  //   如果 产品类型， 产品要求， 图片， 附件，或者是寻合作伙伴 但凡存在一个，就显示
    isBuyerHasDetail(){
      return (
        (this.detailObj.productTypes && this.detailObj.productTypes.length) ||
          this.detailObj.productRequest ||
          (this.detailObj.fileAppendixList && this.detailObj.fileAppendixList.length) ||
          (this.detailObj.pictureAppendixList && this.detailObj.pictureAppendixList.length)||
        this.detailObj.productRequest || this.detailObj.partnerDescription || this.detailObj.cooperateWay
      )
    }
  },
  mounted() {
    // console.log('this.$route.query.postOwnerId ->',this.$route.query.postOwnerId)
  },
  methods: {
    initDetailData() {
      // console.log('this.postType ->',this.postType)
      // this.initDetailParam.userId = this.$route.query.postOwnerId
      this.initDetailParam.reportId = this.reportId
      // this.initDetailParam.reportId = this.postId
      /**
       * 获取合作伙伴详情
       * 合作伙伴传参  partnerId
       */
      if(this.postType === 2){
        delete this.initDetailParam.postId
        this.initDetailParam.partnerId = this.postId
        api.getBackEndPartnerDetailAdmin(this.initDetailParam).then(res=>{
          // console.log('res.data ->',res.data)
          this.detailObj = res.data
          // 如果举报来的，就获取reportStatus, 否则 status
          this.isUser === 2? this.status = Number(this.detailObj.reportStatus) : this.status = Number(this.detailObj.status)
        })
      }
      /**
       * 获取买家卖家详情
       * 传参  postId
       */
      else{
        delete this.initDetailParam.partnerId
        this.initDetailParam.postId = this.postId
        api.getBackEndPostDetailAdmin(this.initDetailParam).then(res=>{
          // console.log('res.data ->',res.data)
          this.detailObj = res.data
          // 如果举报来的，就获取reportStatus, 否则 status
          this.isUser === 2? this.status = Number(this.detailObj.reportStatus) : this.status = Number(this.detailObj.status)
          // this.status = Number(this.detailObj.status)
        })
      }
    },
    
    // 判断是否价格可谈
    isNegotiable(num){
      if(num===0){
        return this.$t('Negotiable')
      }else{
        return this.$t('NonNegotiable')
      }
    },
    
    // 产品类型列表变str
    toString(list){
      let str = ''
      if(list){
        str = list.join('，')
      }
      return str
    },
    
    // 后退按钮
    back(){
      this.$router.push({
        name:this.$route.query.routerName
      })
    },
    
  //   打开走马灯弹窗
    openCarousel(){
      this.carouselFlag = true
      console.log('this.carouselFlag ->',this.carouselFlag)
    },
    
  //   审核帖子
    reviewPost(postId, action){
      if(this.right<2){
        this.$message.error(this.$t('NoRight'))
      }else{
        api.getReviewPost({
          currentAdmin:this.adminId,
          postId,
          postType:this.postType===2?1:0,    //  0 买家/卖家   1 合作伙伴
          action             // 0：通过  1拒绝
        }).then(res=>{
          console.log('res.data ->',res.data)
          if(res.code===200 && res.data){
            this.initDetailData()
          }else{
            this.$message.error(this.$t('SysBusy'));
          }
        })
      }
    },
    
  //   本系统删除该贴 （通过的接收/举报帖子）   全系统删除（成员帖子）
    async deletePosts(barId,type){
      if(this.right<3){
        this.$message.error(this.$t('NoRight'))
      }else{
        const confirmResult = await this.$confirm(
            this.$t('ConfirmDelete'),
            this.$t('Attention'),
            {
              confirmButtonText: this.$t('Confirm'),
              cancelButtonText: this.$t('Cancle'),
              dangerouslyUseHTMLString: true,
              type: "warning",
            }
        ).catch((error) => error);
        // 进行判断拿confirmResult看是否等于confirm不能于则是取消
        if (confirmResult !== "confirm") {
          return this.$message.info(this.$t('Canceled'));
        }
        let param = {
          barId,
          type,   // 1:本系统删除    2:全系统删除
          currentAdmin:this.adminId,
        }
        type === 1? param.chamberId = this.chamberId:delete param.chamberId
        api.getDeleteById(param).then(res=>{
          // console.log('res.data ->',res.data)
          if(res.code===200){
            this.$message.success(this.$t('Deleted'));
            this.back()
          }
        })
      }
    },
    
  //   审核举报帖子
    reviewReportPost(reportId,action){
      if(this.right<3){
        this.$message.error(this.$t('NoRight'))
      }else{
        api.getUpdateId({
          reportId: reportId            // 0：通过  1拒绝
        }).then(res=>{
          console.log('res.code ->',res.code)
          if(res.code===200 && res.data){
            this.initDetailData()
          }else{
            this.$message.error(this.$t('SysBusy'));
          }
        })
      }
    },
    
  //   上一条下一条     0上一条   1下一条
    async preAfter(upOrDown){
      // console.log('头请求this.postId ->',this.postId)
      let param1 = {
        currentAdmin: this.adminId,
        postId:this.postId,
        upOrDown
      }
      let param2 = {}
      if(this.isUser===1){
        await api.preAfter1(param1).then(res=>{
          res.data.postId?param2 = res.data:this.$message.success(this.$t('TheEnd'));
        })
      }else if(this.isUser===0){
        await api.preAfter2(param1).then(res=>{
          res.data.postId?param2 = res.data:this.$message.success(this.$t('TheEnd'));
        })
      }else{
        await api.preAfter3(param1).then(res=>{
          res.data.postId?param2 = res.data:this.$message.success(this.$t('TheEnd'));
        })
      }
      param2.postId?this.postId = param2.postId:''
      param2.reportId?this.reportId = param2.reportId:''
      param2.postType !== undefined?this.postType = param2.postType:''
      // console.log('尾请求param2 ->',param2.postType, this.postType)
      
      await this.initDetailData()
    }
    
  },
};
</script>
<style lang="scss" scoped>
.details {
  display: flex;
  flex-flow: column nowrap;
  
  
  //------------------------------------------带标题的帖子-------------------------------
  .detail-view{
    .item-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      min-height: 268px;
      background-color: #ebf0f6;
      margin-bottom: 15px;
      padding: 20px 30px 10px;
    
      // 产品类型
      .top-product-type-box {
        color: #7b7b7b;
      }
    
      // 顶部区域
      .top-box {
        display: flex;
      
        .top-left-box {
          padding-right: 20px;
          margin-right: 20px;
          border-right: 1px dashed #3a3b3d;
        
          .img-box {
            img {
              width: 120px;
              height: 120px;
            }
          }
        
          & > p {
            margin-top: 10px;
            text-align: center;
            font-size: 14px;
          }
        }
      
        .top-right-box {
          flex: 1;
          display: flex;
          justify-content: space-between;
          padding-right: 20px;
  
          .ellipsis {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        
          .info-box {
            flex: 1;
            width: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-right: 40px;
          
            & > .title-box {
              color: #3d3d3d;
              line-height: 20px;
            }
          
            .info-detail {
              color: #7b7b7b;
              font-size: 14px;
            
              li {
                margin-bottom: 10px;
              }
            
              li:last-child {
                margin-bottom: 0;
              }
            }
          
            & > .accept-price-box {
              color: #7b7b7b;
              font-size: 14px;
            }
          }
        
          .btn-box {
            .btn-item {
              width: 100px;
              line-height: 30px;
              text-align: center;
              margin-bottom: 10px;
              font-size: 14px;
              border: 1px solid #010101;
              cursor: pointer;
            }
          
            .btn-item:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    
      // 底部区域
      .bottom-box {
        display: flex;
        color: #3d3d3d;
        font-size: 14px;
      
        list-style-type: none;
      
        & > .flex1 {
          flex: 1;
        }
      
        & > .flex2 {
          flex: 2;
        }
      
        & > li:last-child {
          padding-right: 20px;
          text-align: right;
        
          span {
            margin-left: 5px;
          }
        }
      }
    
    }
  }
  // -----------------------------------------------详情里的详情-----------------------
  .detail-detail{
    display: flex;
    flex-flow: row;
    margin: 10px 0;
    min-height: 200px;
    max-height: 400px;
    overflow: auto;
    background-color: #ebf0f6;
    padding: 20px;
    
    .detail-img{
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      &> div{
        position: relative;
        
        .pile{
        //background-color: #7b7b7b;
        position:absolute;
        bottom: 20px;
        right:25px;
        width: 30px;
        height: 30px;
        background-color: rgba(164,164,166, 0.8);
        cursor:pointer;
        box-shadow: 0 0 10px white;
        border-radius: 10px;
  
        i{
          display:block;
          margin:5px;
          font-size:20px
        }
      }
      }
      .imgs{
        width: 200px;
        height: 200px;
        margin: 10px 20px;
      }
      
    }
    
    .detail-text{
  
      .detail-info{
        display: flex;
        flex-flow: column nowrap;
    
        >& div{
          margin: 5px 0;
        }
    
        p{
          text-indent: 40px;
        }
      }
  
      .attachments{
        li{
          list-style: none;
        }
        i{
          margin: 0 10px;
        }
      }
    }
  }
  
  //--------------------------------------------补充-----------------------------------------
  .detail-additional{
    margin: 10px 0;
    h4{
      margin-bottom: 10px;
    }
    .detail-style{
      background-color: #EBF0F6;
      padding: 20px;
      color: #6e6e6e;
    }
  }
  
  //------------------------------------------------操作按钮------------------------------------
  .detail-operation{
    margin-top: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    
    .btn-no{
      background-color: #8F1B19;
      color: white;
    }
    .btn-man-p{
      background-color: #85B32B;
      color: white;
    }
    .btn-auto-p{
      background-color: #C7A334;
      color: white;
    }
    
    .el-button{
      height: 60px;
      width: 400px;
    }
  
    .el-button+.el-button{
      margin: 0;
    }
  }
  
  //-----------------------------------------上一条/下一条---------------------------------
  .pre-aft{
    margin-top: 30px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    
    .el-button{
      height: 70px;
      width: 500px;
      background-color: #4d7da7;
      color: white;
      
      &:hover{
        background-color: #80a6d0 !important;
      }
    }
  }
}
</style>
  